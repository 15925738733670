import Vue from 'vue'
import VueRouter from 'vue-router'
import { getCookie} from "@/util/cookie";
Vue.use(VueRouter)

const routes = [
  {
    name: 'Home',
    path: '/',
    redirect: '/login/1',

    component: () => import('../layout'),
    children: [
      {
        path: '/login',
        name: 'login',
        redirect: '/login/1',
        meta: {
          isLogin: true
        },
        component: () => import('../views/login.vue'),
        children: [{
          path: '/login/:id',
          name: 'loginChild',
          meta: {
            isLogin: true
          },
          component: () => import('../views/login/login')
        },
        ]
      },
      {
        path: 'create',  //新建账户
        name: 'create',
        component: () => import('../views/create.vue'),
        meta: {
          isLogin: true
        },
      },
      {
        path: '/resetPassword',  //忘记密码
        name: 'resetPassword',
        component: () => import('../views/create.vue'),
        meta: {
          isLogin: true
        },
      },
      {
        path: '/index',  //首页
        name: 'panel',
        meta: {
          title: '面板'
        },
        component: () => import('../views/panel.vue')
      },
      {
        path: 'detail',
        name: 'detail',
        meta: {
          title: '二级面板'
        },
        component: () => import('../views/industry_second_level')
      }
    ]
  },
]


const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  // if (getCookie('isLogout')) {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('userInfo');
  //   next();
  //   return;
  // }
  if (localStorage.getItem('token')) {
    if (to.meta.isLogin) {
      next({ name: 'panel' })
    } else {
      next()
    }
  } else {
    if (to.meta.isLogin) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
